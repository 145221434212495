<template>
    <div class="p-grid" v-show="Object.keys(layoutDetails).length !== 0">
      <Toast />
        <div class="p-col-12">
            <div class="card p-fluid">
                <div class="p-d-flex p-jc-between p-mb-2">
                    <div>
                        <h4>
                            <strong>레이아웃</strong>
                        </h4>
                        
                    </div>
                    <div>
                        <p v-if="layoutDetails.release_date !== ''"><strong>최근 배포일 :</strong> {{ formatDate(layoutDetails.release_date) }}</p>
                    </div>
                </div>
                <div class="chat-room" v-for="(item, i) in layoutDetails.submain_area" :key="i">
                    <div class="chat-room-content">
                        <!-- <i class="pi pi-arrow-up"></i>
                        <i class="pi pi-arrow-down"></i> -->
                        <span v-if="item.type === 'keyword'">키워드 채팅방</span>
                        <span v-if="item.type === 'professional'">전문 채팅방</span>
                    </div>
                    <InputSwitch v-model="item.status" class="toggle-btn" />
                    <span v-if="item.type === 'keyword'" @click="$router.push({path: '/KeywordChatroom/KeywordChatroom'})" class="correction">수정</span>
                    <span v-if="item.type === 'professional'" @click="$router.push({path: '/ProfessionalChatroom/ProfessionalChatroom'})" class="correction">수정</span>
                </div>

                <!-- <div class="chat-room">
                    <div class="chat-room-content">
                        <span>professional chat room</span>
                    </div>
                    <InputSwitch v-model="checked2" class="toggle-btn" />
                    <span @click="$router.push({path: '/ProfessionalChatroom/ProfessionalChatroom'})" class="correction">수정</span>
                </div> -->
            </div>
        </div>
        <div class="p-col-12">
            <div class="p-d-flex p-jc-between p-ai-lg-center p-ai-start p-flex-column p-flex-lg-row">
                <div class="p-mb-4 p-mb-lg-0"></div>
                <div>
                    <Button class="p-button-primary p-mr-2" @click="openModal('save')" @accept="accept" @close="showConfirmModal = false">저장</Button>
                    <Button class="p-button-danger p-mr-2" @click="layoutDetails.has_saved_data === 'yes' ? openModal('distribution') : openModal('notSaved')" @accept="accept" @close="showConfirmModal = false">배포</Button>
                    <Button class="p-button-secondary p-ml-auto" @click="showTableModal = true" @accept="showConfirmModal = false">배포내역</Button>
                </div>
            </div>
        </div>
    </div>
    <FloatLabel> </FloatLabel>
    <ConfirmationModal :buttonType="buttonType" :showModal="showConfirmModal" @close="showConfirmModal = false" @accept="accept"/>
    <TableModal :buttonType="buttonType" :showModal="showTableModal" @close="showTableModal = false" />
</template>

<script setup>
import { ref, onMounted } from 'vue';
import InputSwitch from 'primevue/inputswitch';
import SubmainManagementService from '../../service/API/SubmainManagementService';
import { getCurrentInstance } from 'vue';
import ConfirmationModal from '@/components/modal/ConfirmationModal.vue';
import TableModal from '@/components/modal/TableModal.vue';
const { proxy } = getCurrentInstance();
const showConfirmModal = ref(false);
const buttonType = ref('');
const showTableModal = ref(false);
const layoutDetails = ref({});

const submainManaggementService = new SubmainManagementService();
const openModal = (type) => {
    buttonType.value = type;
    showConfirmModal.value = true; 
};

const fetchLayoutList = async () => {
    try {
        const response = await submainManaggementService.layoutList();
        console.log(response);
        layoutDetails.value = response.data;
        layoutDetails.value.submain_area.forEach((item) => {
            item.status = item.status === 'active' ? true : false;
        });
    } catch (error) {
        console.log(error);
    }
};

const accept = () => {
  if (buttonType.value === 'save') {
    saveLayout();
  } else if (buttonType.value === 'distribution') { 
    publishLayout();
  }
  showConfirmModal.value = false; 
    
};

const saveLayout = async () => {
    const data = {
      keyword_status: layoutDetails.value.submain_area[0].status ? "active" : "inactive",
      professional_status: layoutDetails.value.submain_area[1].status ? "active" : "inactive"
    }
    try {
        const response = await submainManaggementService.layoutSave(data);
        console.log(response);
        fetchLayoutList();
        setTimeout(() => {
          proxy.$toast.add({ severity: 'success', summary: 'Successfull', detail: '변경 사항이 성공적으로 저장되었습니다.', life: 3000 });
        }, 1000);
    } catch (error) {
        console.log(error);
        setTimeout(() => {
          proxy.$toast.add({ severity:'error', summary:'Error', detail: '문제가 발생했습니다.', life: 3000 });
      }, 1000);
    }
};

const publishLayout = async () => {
    const data = {
      will_publish: 'yes'
    }
    try {
        const response = await submainManaggementService.layoutPublish(data);
        console.log(response); 
        fetchLayoutList();
        setTimeout(() => {
          proxy.$toast.add({ severity: 'success', summary: 'Successfull', detail: '변경 내용이 성공적으로 게시되었습니다.', life: 3000 });
        }, 1000);
    } catch (error) {
        setTimeout(() => {
          proxy.$toast.add({ severity:'error', summary:'Error', detail: '사용 가능한 초안 데이터가 없습니다.', life: 3000 });
      }, 1000);
      console.log(error);
    }
};

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');
  return `${year}.${month}.${day} ${hours}:${minutes}:${seconds}`;
}

onMounted(() => {
      fetchLayoutList();
    });


</script>

<style scoped>
.layout-container {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
}

.chat-room {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 10px 20px;
    margin-bottom: 15px;
}

.chat-room-content {
    display: flex;
    align-items: center;
    gap: 10px;
}

i {
    font-size: 1.2rem;
}

.toggle-btn {
    margin-left: auto;
}

.correction {
    margin-left: 15px;
    font-size: 0.85rem;
    cursor: pointer;
}
</style>

<!-- <template>
    <div class="p-card p-mt-3 p-p-3 layout-container">
      <div class="p-d-flex p-jc-between p-ai-center p-mb-3">
        <h4 class="p-m-0">레이아웃</h4>
        <span class="last-deployment">최근 배포일 : YYYY.MM.DD HH:MM:SS</span>
      </div>
  
      <div class="chatroom-list p-d-flex p-flex-column p-ai-start p-p-3 p-mb-3">
        <div class="chatroom-item p-d-flex p-jc-between p-ai-center p-py-2 p-w-full">
          <div class="p-d-flex p-ai-center">
            <img src="your-icon-url-here" alt="icon" class="chatroom-icon p-mr-2" />
            <span class="chatroom-title">키워드 채팅방</span>
          </div>
          <div class="p-d-flex p-ai-center">
            <p-inputswitch v-model="chatroom1" class="p-mr-2" />
            <p-button label="수정" icon="pi pi-pencil" class="p-button-text p-button-sm p-mr-2" />
          </div>
        </div>
  
        <div class="chatroom-item p-d-flex p-jc-between p-ai-center p-py-2 p-w-full">
          <div class="p-d-flex p-ai-center">
            <img src="your-icon-url-here" alt="icon" class="chatroom-icon p-mr-2" />
            <span class="chatroom-title">전문 채팅방</span>
          </div>
          <div class="p-d-flex p-ai-center">
            <p-inputswitch v-model="chatroom2" class="p-mr-2" />
            <p-button label="수정" icon="pi pi-pencil" class="p-button-text p-button-sm p-mr-2" />
          </div>
        </div>
      </div>
  
      <div class="p-d-flex p-jc-end p-mt-3">
        <p-button label="저장" class="p-button-primary p-mr-2" />
        <p-button label="배포" class="p-button-danger p-mr-2" />
        <p-button label="배포내역" class="p-button-secondary" />
      </div>
    </div>
  </template>
  
  <script>
  import { ref } from 'vue';
  import InputSwitch from 'primevue/inputswitch';
  import Button from 'primevue/button';
  
  export default {
    components: {
      'p-inputswitch': InputSwitch,
      'p-button': Button,
    },
    setup() {
      const chatroom1 = ref(false);
      const chatroom2 = ref(true);
      return { chatroom1, chatroom2 };
    },
  };
  </script>
  
  <style scoped>
  .layout-container {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .last-deployment {
    font-size: 14px;
    color: #888;
  }
  
  .chatroom-list {
    border: 2px dashed red;
    border-radius: 4px;
    background-color: white;
    width: 100%;
  }
  
  .chatroom-item {
    border-bottom: 1px solid #ddd;
  }
  
  .chatroom-item:last-child {
    border-bottom: none;
  }
  
  .chatroom-icon {
    width: 40px;
    height: 40px;
  }
  
  .chatroom-title {
    font-size: 16px;
  }
  
  .p-button-sm {
    padding: 0.25rem 0.5rem;
  }
  
  .p-button-text {
    color: #007bff;
  }
  </style>
   -->
<style scoped>
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
	background: #0D0D0D;
}
</style>