// import axios from 'axios';
import { hanatourApi } from '../../main.js';
export default class SubmainManagementService {

    async layoutList() {
        return await hanatourApi.get(`/admin/submain/layout-list`)

    }
    async layoutSave(data) {
        return await hanatourApi.post(`/admin/submain/layout-save`, data)
    }


    async layoutPublish(data) {
        return await hanatourApi.post(`/admin/submain/layout-publish`, data)
    }

    async layoutDistributionHistoryList(obj) {
        return await hanatourApi.get(`/admin/submain/distribution-history?record_count=${obj.record_count}&page=${obj.page}`)
    }

    async keywordChatroomList(groupID, obj) {
        var params = new URLSearchParams(obj).toString();
        return await hanatourApi.get(`/admin/submain/keyword-chatrooms/${groupID}?${params}`)
    }

    async professionalChatroomList(groupID, obj) {
        var params = new URLSearchParams(obj).toString();
        return await hanatourApi.get(`/admin/submain/professional-chatrooms/${groupID}?${params}`)
    }

    async keywordChatroomSave(data) {
        return await hanatourApi.post(`/admin/submain/keyword-chatroom/save`, data)
    }

    async professionalChatroomSave(data) {
        return await hanatourApi.post(`/admin/submain/professional-chatroom/save`, data)
    }

    async keywordChatroomDetails() {
        return await hanatourApi.get(`/admin/submain/keyword-chatroom/details`)
    }

    async professionalChatroomDetails() {
        return await hanatourApi.get(`/admin/submain/professional-chatroom/details`)
    }

    async groupAdd(data) {
        return await hanatourApi.post(`/admin/submain/group/add`, data)
    }

    async groupOrdering(data) {
        return await hanatourApi.post(`/admin/submain/group/ordering`, data)
    }

    async groupDelete(data) {
        return await hanatourApi.post(`/admin/submain/group/delete`, data)
    }

    async chatroomOrdering(data) {
        return await hanatourApi.post(`/admin/submain/chatroom/ordering`, data)
    }

    async chatroomDelete(data) {
        return await hanatourApi.post(`/admin/submain/chatroom/delete-bulk`, data)
    }

    async chatroomBulletSave(data) {
        return await hanatourApi.post(`/admin/submain/chatroom/bullet-save`, data)
    }

    async chatroomBulletDelete(data) {
        return await hanatourApi.post(`/admin/submain/chatroom/bullet-delete`, data)
    }

    async chatroomBulletDetails(id) {
        return await hanatourApi.get(`/admin/submain/chatroom/bullet-details/${id}`)
    }

}