<template>
    <div v-if="props.showModal" class="modal-overlay">
        <!-- <div class="modal-content"> -->

        <div class="new-table-modal-content">
            <h5>서브메인 레이아웃 배포내역</h5>
            <DataTable :value="historyData.lists" rowGroupMode="subheader" :loading="loading1"
                groupRowsBy="data.version" sortMode="single" sortField="data.version" :sortOrder="1" scrollable
                scrollHeight="400px">
                <template #empty> 데이터가 없습니다. </template>
                <template #loading> 데이터를 로드 중입니다. 기다리다. </template>

                <Column header="버전" style="min-width: 12rem" field="version">
                    <template #body="{ data }">
                        <span class="p-column-title">Version</span>
                        {{ data.version }}
                    </template>
                </Column>
                <Column header="배포자" style="min-width: 12rem" field="distributor">
                    <template #body="{ data }">
                        <span class="p-column-title">Distributor</span>
                        {{ data.distributor }}
                    </template>
                </Column>
                <Column header="배포일시" style="min-width: 12rem" field="distribution_date">
                    <template #body="{ data }">
                        <span class="p-column-title">Distribution Date</span>
                        {{ formatDate(data.distribution_date) }}
                    </template>
                </Column>
            </DataTable>
            <div class="pagination-nos">
                <div class="p-paginator p-component p-paginator-bottom">
                    <button @click="paginate(1)" :class="{ 'p-disabled': historyData.current_page === 1 }"
                        class="p-paginator-first p-paginator-element p-link" type="button"
                        :disabled="historyData.current_page === 1">
                        <span class="p-paginator-icon pi pi-angle-double-left"></span>
                        <span class="p-ink"></span>
                    </button>
                    <button @click="paginate(historyData.current_page - 1)"
                        :class="{ 'p-disabled': historyData.current_page === 1 }"
                        class="p-paginator-next p-paginator-element p-link" type="button"
                        :disabled="historyData.current_page === 1">
                        <span class="p-paginator-icon pi pi-angle-left"></span>
                        <span class="p-ink"></span>
                    </button>
                    <span class="p-paginator-pages">
                        <button
                            v-for="(page, index) in get_paginator_page(historyData.total_pages, historyData.current_page)"
                            :key="index" @click="paginate(page)" class="p-paginator-page p-paginator-element p-link"
                            :class="{ 'p-highlight': page === historyData.current_page }" type="button">
                            {{ page }}<span class="p-ink"></span>
                        </button>
                    </span>
                    <button @click="paginate(historyData.current_page + 1)"
                        :class="{ 'p-disabled': historyData.current_page === historyData.total_pages }"
                        class="p-paginator-next p-paginator-element p-link" type="button"
                        :disabled="historyData.current_page === historyData.total_pages">
                        <span class="p-paginator-icon pi pi-angle-right"></span>
                        <span class="p-ink"></span>
                    </button>
                    <button @click="paginate(historyData.total_pages)"
                        :class="{ 'p-disabled': historyData.current_page === historyData.total_pages }"
                        :disabled="historyData.current_page === historyData.total_pages"
                        class="p-paginator-last p-paginator-element p-link" type="button">
                        <span class="p-paginator-icon pi pi-angle-double-right"></span>
                        <span class="p-ink"></span>
                    </button>
                </div>

                <div class="table-pagination">
                    <label for="order">
                        {{ historyData.total_records }}개 중 {{ historyData.current_page * historyData.pagination_limit -
            historyData.pagination_limit + 1 }} -
                        {{ historyData.total_pages === historyData.current_page ? historyData.total_records :
            historyData.current_page *
            historyData.pagination_limit - historyData.pagination_limit + 10 }}
                    </label>
                </div>
            </div>

            <div class="modal-footer-new right">
                <button class="p-button p-component p-button-secondary" @click="closeModal">닫기</button>
            </div>
        </div>


    </div>
</template>

<script setup>
import { onMounted, ref, reactive, watch } from 'vue';
import utils from '@/service/utils';
import { defineEmits, defineProps } from 'vue';
import SubmainManagementService from '../../service/API/SubmainManagementService';
const props = defineProps({
    showModal: {
        type: Boolean,
        required: true,
    },
    buttonType: {
        type: String,
        required: true,
    },
});
const submainManaggementService = new SubmainManagementService();
const historyData = reactive({
    pagination_limit: 10,
    current_page: 1,
    total_records: 0,
    total_pages: 0,
    lists: [],
});
const loading = ref(false);
const emit = defineEmits(['close']);

const closeModal = () => {
    emit('close');
};

watch(() => props.showModal, (value) => {
    if (value) {
        fetchLayoutDistributionHistoryList();
    }
});


const fetchLayoutDistributionHistoryList = async (page = null) => {
    loading.value = true;
    try {
        const response = await submainManaggementService.layoutDistributionHistoryList({
            record_count: historyData.pagination_limit,
            page: page === null ? historyData.current_page : page,
        });
        historyData.lists = response.data.history;
        historyData.total_records = response.data.total_records;
        historyData.total_pages = response.data.total_pages;
        historyData.current_page = response.data.current_page;

        loading.value = false;
    } catch (error) {
        loading.value = false;
        console.log(error);
    }
};

const get_paginator_page = (total, active) => {
    return utils.paginate(total, active);
};

const paginate = (page) => {
    fetchLayoutDistributionHistoryList(page);
}

const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return `${year}.${month}.${day} ${hours}:${minutes}:${seconds}`;
}


onMounted(() => {
    fetchLayoutDistributionHistoryList();
});
</script>

<style scoped>
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    max-width: 300px;
    width: 100%;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.new-table-modal-content {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    max-width: 685px;
    width: 100%;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.modal-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
}

.modal-subtitle {
    font-size: 14px;
    margin-bottom: 20px;
}

.modal-button {
    background-color: #3c6efb;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.modal-button:hover {
    background-color: #2b50e3;
}

.table-pagination {
	position: absolute;
	width: 100%;
	max-width: 120px;
	right: 0;
	top: 15px;
}

.modal-footer-new {
    display: flex;
    gap: 16px;
    justify-content: center;

}

.right {
    justify-content: flex-end;
}

.pagination-nos {
    position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
}

</style>